export default class ConfigEnvRequiredError extends Error {
  constructor(env: string) {
    super(`${env} env required`);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
export class ConfigEnvError extends Error {
  constructor(error: string) {
    super(error);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
