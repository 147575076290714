import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useStyles } from '../styles/LoadingPage';
import { ILoadingPageProps } from '../types/Components';

const LoadingPage: React.FC<ILoadingPageProps> = ({
  isLoading,
  error
}: ILoadingPageProps) => {
  const classes = useStyles();
  if (isLoading) {
    return (
      <div className={classes.loadingPage}>
        <CircularProgress />
      </div>
    );
  }
  if (error) {
    return (
      <div className={classes.loadingPageProblem}>
        <h3>Sorry, there was a problem loading the page.</h3>
      </div>
    );
  }
  return null;
};

export default LoadingPage;
