import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const LoadingPage: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <h2>Sorry, Page Not Found.</h2>
      <Footer />
    </div>
  );
};

export default LoadingPage;
