import { mergeDeep } from 'immutable';
import React, { Component } from 'react';
import ConfigEnvRequiredError, { ConfigEnvError } from '../errors/Config';
import pkg from '../../package.json';

interface IConfig {
  apiAWSAccessKeyID: string;
  apiAWSSecretAccessKey: string;
  apiHost: string;
  apiToken: string;
  apiReleaseStage: string;
  paymentGatewayIPay88PostURL: string;
  paymentGatewayPayDollarPostURL: string;
  paymentGatewayUPayPostURL: string;
  landingPageRedirectURL: string;
  version: string;
}

export interface IConfigContext {
  state: IConfig;
}

const initialState: IConfig = {
  apiAWSAccessKeyID: '',
  apiAWSSecretAccessKey: '',
  apiHost: '',
  apiToken: '',
  apiReleaseStage: '',
  paymentGatewayIPay88PostURL: '',
  paymentGatewayPayDollarPostURL: '',
  paymentGatewayUPayPostURL: '',
  landingPageRedirectURL: '',
  version: '',
};

export const ConfigContext = React.createContext<IConfigContext>({
  state: initialState
});
export const ConfigContextProvider = ConfigContext.Provider;
export const ConfigContextConsumer = ConfigContext.Consumer;

interface IConfigProviderState {
  readonly data?: IConfig;
  readonly error?: string;
  readonly loaded?: boolean;
}

export default class ConfigProvider extends Component<
  {},
  IConfigProviderState
  > {
  public initialState = initialState;

  public state: IConfigProviderState = {
    data: initialState,
    loaded: false
  };

  public async componentDidMount() {
    await this.setupConfig();
  }

  public async setupConfig() {
    let data: IConfig;
    try {
      data = this.readConfig();
    } catch (err) {
      let message = 'Setup config error occurred';
      if (err.message) {
        message = err.message;
      }
      await this.updateState({ error: message });
      return;
    }
    await this.updateState({ data, loaded: true });
  }

  public render(): React.ReactNode {
    const { children } = this.props;
    const { error, data, loaded } = this.state;
    const value: IConfigContext = {
      state: data || initialState
    };

    if (error) {
      return <div className="error">{error}</div>;
    }

    if (!loaded) {
      return <div className="loading">Loading...</div>;
    }

    return (
      <ConfigContextProvider value={value}>{children}</ConfigContextProvider>
    );
  }

  private updateState = (newState: IConfigProviderState) =>
    new Promise<IConfigProviderState>(resolve => {
      let s: IConfigProviderState;
      this.setState(
        prevState => {
          s = mergeDeep(prevState, newState);
          return s;
        },
        () => resolve(s)
      );
    });

  private readConfig(): IConfig {
    const {
      REACT_APP_API_AWS_ACCESS_KEY_ID: apiAWSAccessKeyID,
      REACT_APP_API_AWS_SECRET_ACCESS_KEY: apiAWSSecretAccessKey,
      REACT_APP_API_HOST: apiHost,
      REACT_APP_API_RELEASE_STAGE: apiReleaseStage,
      REACT_APP_API_TOKEN: apiToken,
      REACT_APP_BUGSNAG_API_KEY: bugsnagAPIKey,
      REACT_APP_LANDING_PAGE_REDIRECT_URL: landingPageRedirectURL,
      REACT_APP_PAYMENT_GATEWAY_IPAY88_POST_URL: paymentGatewayIPay88PostURL,
      REACT_APP_PAYMENT_GATEWAY_PAYDOLLAR_POST_URL: paymentGatewayPayDollarPostURL,
      REACT_APP_PAYMENT_GATEWAY_UPAY_POST_URL: paymentGatewayUPayPostURL,
    } = process.env;

    let version: string
    if (apiReleaseStage === 'dev' || apiReleaseStage === 'stage') {
      version = `${pkg.version} ${new Date().getTime()}`
    } else {
      version = pkg.version
    }

    if (!apiAWSAccessKeyID)
      throw new ConfigEnvRequiredError('REACT_APP_API_AWS_ACCESS_KEY_ID');

    if (!apiAWSSecretAccessKey)
      throw new ConfigEnvRequiredError('REACT_APP_API_AWS_SECRET_ACCESS_KEY');

    if (!apiHost) throw new ConfigEnvRequiredError('REACT_APP_API_HOST');

    if (!apiReleaseStage)
      throw new ConfigEnvRequiredError('REACT_APP_API_RELEASE_STAGE');

    if (!paymentGatewayIPay88PostURL)
      throw new ConfigEnvRequiredError(
        'REACT_APP_PAYMENT_GATEWAY_IPAY88_POST_URL'
      );

    if (!paymentGatewayPayDollarPostURL)
      throw new ConfigEnvRequiredError(
        'REACT_APP_PAYMENT_GATEWAY_PAYDOLLAR_POST_URL'
      );

    if (!paymentGatewayUPayPostURL)
      throw new ConfigEnvRequiredError(
        'REACT_APP_PAYMENT_GATEWAY_UPAY_POST_URL'
      );

    if (
      apiReleaseStage !== 'dev' &&
      apiReleaseStage !== 'stage' &&
      apiReleaseStage !== 'demo' &&
      apiReleaseStage !== 'prod'
    )
      throw new ConfigEnvError(
        'REACT_APP_API_RELEASE_STAGE is an invalid release stage'
      );

    if (!apiToken) throw new ConfigEnvRequiredError('REACT_APP_API_TOKEN');

    if (!bugsnagAPIKey)
      throw new ConfigEnvRequiredError('REACT_APP_BUGSNAG_API_KEY');

    if (!landingPageRedirectURL)
      throw new ConfigEnvRequiredError('REACT_APP_LANDING_PAGE_REDIRECT_URL');

    return {
      apiAWSAccessKeyID,
      apiAWSSecretAccessKey,
      apiHost,
      apiToken,
      apiReleaseStage,
      paymentGatewayIPay88PostURL,
      paymentGatewayPayDollarPostURL,
      paymentGatewayUPayPostURL,
      landingPageRedirectURL,
      version,
    };
  }
}
