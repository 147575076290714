import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingPage from '../components/LoadingPage';
import NotFound from '../components/NotFound';
import GA from '../utils/Tracker';
const Payment = React.lazy(() => import('../pages/Payment'));
const Landing = React.lazy(() => import('../pages/Landing'));
const Term = React.lazy(() => import('../pages/Term'));

const Routes = () => {
  return (
    <React.Suspense fallback={<LoadingPage isLoading />}>
      <Switch>
        <Route exact path="/not_found/" component={GA(NotFound)} />
        <Route exact path="/terms_and_conditions/" component={GA(Term)} />
        <Route exact path="/:id/" component={GA(Payment)} />
        <Route exact path="/" component={GA(Landing)} />
        <Redirect to="/not_found/" />
      </Switch>
    </React.Suspense>
  );
};

export default Routes;
