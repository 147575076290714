import { mergeDeep } from 'immutable';
import React, { Component, ContextType } from 'react';
import { ConfigContext } from './Config';

interface IState {
  readonly loading?: boolean;
  readonly error?: string;
}

export interface IContext {
  actions: {
    updateLoading(status: boolean): void;
  };
  state: IState;
}

const initialState: IState = {
  loading: false
};

export const Context = React.createContext<IContext>({
  actions: {
    updateLoading(status: boolean) {
      return Promise.resolve();
    }
  },
  state: initialState
});

export const AppContextProvider = Context.Provider;
export const AppContextConsumer = Context.Consumer;

interface IProviderProps {
  children: React.ReactNode;
}

interface IProviderState {
  data: IState;
}

export default class Provider extends Component<
  IProviderProps,
  IProviderState
> {
  public static initialState = initialState;

  public static contextType = ConfigContext;
  public context!: ContextType<typeof ConfigContext>;

  public state: IProviderState = {
    data: initialState
  };

  public updateLoading = async (status: boolean) => {
    await this.updateState({
      loading: status
    });
  };

  public render() {
    const { children } = this.props;
    const { data } = this.state;
    const value: IContext = {
      actions: {
        updateLoading: this.updateLoading
      },
      state: data
    };

    return <AppContextProvider value={value}>{children}</AppContextProvider>;
  }

  private updateState = (newData: IState) =>
    new Promise<IState>(resolve => {
      let s: IState;
      this.setState(
        prevState => {
          const data = mergeDeep(prevState, { data: newData });
          s = data.data;
          return data;
        },
        () => resolve(s)
      );
    });
}
