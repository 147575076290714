import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { $color4 } from './Variables';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPage: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '50%',
      left: '50%',
      zIndex: theme.zIndex.drawer,
      backgroundColor: $color4,
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: 'auto',
      '& .loader': {
        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          marginTop: '50%'
        }
      }
    },
    loadingPageProblem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '100px 0',
      textAlign: 'center'
    }
  })
);
