import React from 'react';
import './App.css';
import Provider from './contexts/Provider';
import Routes from './routes/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { globalTheme } from './styles/GlobalTheme';

const App: React.FC = () => {
  return (
    <Provider>
      <Router>
        <ThemeProvider theme={globalTheme}>
          <Routes />
        </ThemeProvider>
      </Router>
    </Provider>
  );
};

export default App;
