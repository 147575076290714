import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const {
  REACT_APP_API_RELEASE_STAGE: apiReleaseStage,
  REACT_APP_BUGSNAG_API_KEY: bugsnagAPIKey
} = process.env;

let bugsnagReleaseStage = 'development';
switch (apiReleaseStage) {
  case 'stage':
    bugsnagReleaseStage = 'staging';
    break;
  case 'demo':
    bugsnagReleaseStage = 'demo';
    break;
  case 'prod':
    bugsnagReleaseStage = 'production';
    break;
  default:
    bugsnagReleaseStage = 'development';
    break;
}

const bugsnagClient = bugsnag({
  apiKey: bugsnagAPIKey!,
  releaseStage: bugsnagReleaseStage
});

bugsnagClient.use(bugsnagReact, React);

export const ErrorBoundary = bugsnagClient.getPlugin('react');

export default bugsnagClient;
