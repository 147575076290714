import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { ConfigContext, IConfigContext } from '../contexts/Config';

const useStyles = makeStyles(theme => ({
  footer: {
    fontSize: '0.60em'
  },
  container: {
    // position: 'absolute',
    // bottom: '0',
    width: '100%',
    margin: '1em 0'
  }
}));

const Footer: React.FC = () => {
  const config: IConfigContext = useContext(ConfigContext);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        className={classes.footer}
      >
        {'Copyright © '}
        {new Date().getFullYear()} - TRB Ventures Sdn Bhd (1041201-U). All
        rights reserved.
        <br />
        <b>
          {' '}
          <Link color="inherit" href="https://mhub.my" target="_blank">
            MHub
          </Link>{' '}
          IS POWERED BY TRB VENTURES SDN BHD
        </b>
        <br />v{config.state.version}
      </Typography>
    </div>
  );
};

export default Footer;
