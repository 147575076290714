import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  appBar: {
    background: 'linear-gradient(180deg, #499BB3 0%, #49B3B1 100%)',
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.appBar}>
      <Container component="main" maxWidth="sm">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Payment Deposit
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
