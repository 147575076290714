import { createMuiTheme } from '@material-ui/core/styles';
import { $fontFamily, $color16 } from './Variables';

export const globalTheme = createMuiTheme({
  palette: {
    primary: {
      main: $color16,
    },
  },
  typography: {
    // 'fontFamily': '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    'fontFamily': 'Montserrat',
    'fontSize': 12,
    'fontWeightLight': 300,
    'fontWeightRegular': 400,
    'fontWeightMedium': 500
  },
  overrides: {
    // Style sheet name ⚛️
    MuiContainer: {
      root: {
        fontFamily: $fontFamily
      }
    },
    MuiListItem: {
      root: {
        color: `${$color16} !important`
      }
    },
    MuiButton: {
      contained: {
        fontFamily: $fontFamily,
      }
    },
    MuiTypography: {
      caption: {
        fontFamily: $fontFamily,
        letterSpacing: 'unset'
      }
    },
    MuiFab: {
      root: {
        boxShadow: 'unset'
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  }
});
