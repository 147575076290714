export const $color1 = 'rgba(255, 255, 255, 1)';
export const $color2 = 'rgba(0, 0, 0, 0.1)';
export const $color3 = 'rgba(244, 245, 247, 1)';
export const $color4 = 'rgba(0, 0, 0, 0)';
export const $color5 = 'rgba(0, 0, 0, 1)';
export const $color6 = 'rgba(89, 105, 117, 1)';
export const $color7 = 'rgba(150, 156, 164, 1)';
export const $color8 = 'rgba(0, 35, 59, 1)';
export const $color9 = 'rgba(237, 239, 240, 1)';
export const $color10 = 'rgba(217, 228, 235, 1)';
export const $color11 = 'rgba(219, 221, 222, 1)';
export const $color12 = 'rgba(51, 51, 51, 1)';
export const $color13 = 'rgba(231, 234, 237, 1)';
export const $color14 = 'rgba(1, 110, 141, 1)';
export const $color15 = 'rgba(73, 179, 177, 1)';
export const $color16 = 'rgba(0, 148, 179, 1)';
export const $color17 = 'rgba(204, 239, 244, 1)';
export const $color18 = 'rgba(0, 187, 224, 1)';
export const $color19 = 'rgba(53, 151, 236, 1)';
export const $color20 = 'rgba(255,255,255,0)';
export const $color21 = 'rgba(255, 255, 255, 0.75)';
export const $color22 = 'rgba(56, 64, 65, 1)';
export const $color23 = 'rgba(247, 248, 249, 1)';
export const $color24 = 'rgba(197, 197, 197, 1)';
export const $color25 = 'rgba(0, 0, 0, 0.30)';
export const $color26 = 'rgba(73, 179, 177, 0.5)';
export const $color27 = 'rgba(255, 152, 0, 1)';
export const $color28 = 'rgba(255, 245, 229, 1)';
export const $color29 = 'rgba(0, 151, 156, 0.1)';
export const $color30 = 'rgba(0, 0, 0, 0.1)';
export const $color31 = 'rgba(244, 67, 54, 1)';
export const $color32 = 'rgba(252, 122, 113, 1)';
export const $color33 = 'rgba(6, 54, 60, 1)';
export const $color34 = 'rgba(50, 200, 197, 1)';

export const $regular = 400;
export const $medium = 500;
export const $semiBold = 600;
export const $bold = 700;

export const $fontFamily = '"Open Sans", serif';
export const $fontFamily2 = '"Montserrat", serif';

export const $5pt = '0.375rem';
export const $6pt = '0.5rem';
export const $7pt = '0.563rem';
export const $8pt = '0.625rem';
export const $9pt = '0.75rem';
export const $10pt = '0.813rem';
export const $11pt = '0.875rem';
export const $12pt = '1rem';
export const $13pt = '1.063rem';
export const $14pt = '1.125rem';
export const $15pt = '1.25rem';
export const $16pt = '1.313rem';
export const $17pt = '1.375rem';
export const $18pt = '1.5rem';
export const $19pt = '1.55rem';
export const $20pt = '1.6rem';
export const $22pt = '1.8rem';
export const $24pt = '2rem';
export const $26pt = '2.2rem';
export const $27pt = '2.25rem';
export const $28pt = '2.3rem';
export const $29pt = '2.35rem';
export const $30pt = '2.45rem';
export const $32pt = '2.55rem';
export const $34pt = '2.75rem';
export const $36pt = '3rem';
export const $72pt = '6rem';
